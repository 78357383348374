/**
 * Site header
 */
.site-header {
//    this gray bar is kind of annoying
//    border-top: 5px solid $grey-color-dark;
    border-bottom: 1px solid $grey-color-light;
    min-height: 56px;

    // Positioning context for the mobile navigation icon
    position: relative;
}

.site-title {
    font-size: 26px;
    line-height: 56px;
    letter-spacing: -1px;
    margin-bottom: 0;
    float: left;

    &,
    &:visited {
        color: $grey-color-dark;
    }
}

.site-nav {
    float: right;
    line-height: 56px;

    .menu-icon {
        display: none;
    }

    .page-link {
        color: $text-color;
        line-height: $base-line-height;

        // Gaps between nav items, but not on the first one
        &:not(:first-child) {
            margin-left: 20px;
        }
    }

    @include media-query($on-palm) {
        position: absolute;
        top: 9px;
        right: 30px;
        background-color: $background-color;
        border: 1px solid $grey-color-light;
        border-radius: 5px;
        text-align: right;

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $grey-color-dark;
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .page-link {
            display: block;
            padding: 5px 10px;
        }
    }
}



/**
 * Site footer
 */
.site-footer {
    border-top: 1px solid $grey-color-light;
    padding: $spacing-unit 0;
}

.footer-heading {
    font-size: 18px;
    margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

.footer-col-wrapper {
    font-size: 15px;
    color: $grey-color;
    margin-left: -$spacing-unit / 2;
    @extend %clearfix;
}

.footer-col {
    float: left;
    margin-bottom: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
}

.footer-col-1 {
    width: -webkit-calc(35% - (#{$spacing-unit} / 2));
    width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
    width: -webkit-calc(20% - (#{$spacing-unit} / 2));
    width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
    width: -webkit-calc(45% - (#{$spacing-unit} / 2));
    width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
    .footer-col-1,
    .footer-col-2 {
        width: -webkit-calc(50% - (#{$spacing-unit} / 2));
        width:         calc(50% - (#{$spacing-unit} / 2));
    }

    .footer-col-3 {
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

@include media-query($on-palm) {
    .footer-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}



/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 0;
}

h1.page-heading {
    font-size: 30px;
}

h2.page-heading {
    font-size: 25px;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit;
    }
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;
}

.post-link {
    display: block;
    font-size: 24px;
}



/**
 * Posts
 */
.post-header {
    margin-bottom: $spacing-unit;
}

.post-title {
    font-size: 42px;
    letter-spacing: -1px;
    line-height: 1;

    @include media-query($on-laptop) {
        font-size: 36px;
    }
}

.post-content {
    margin-bottom: $spacing-unit;

    h2 {
        font-size: 32px;

        @include media-query($on-laptop) {
            font-size: 28px;
        }
    }

    h3 {
        font-size: 26px;

        @include media-query($on-laptop) {
            font-size: 22px;
        }
    }

    h4 {
        font-size: 20px;

        @include media-query($on-laptop) {
            font-size: 18px;
        }
    }
}

/**
 * Papers
 */
h1.paper-title {
    font-size: 30px;
    margin-bottom: 1.5 * $spacing-unit;
}

.paper-authors:first-child {
    color: $grey-color;
    width: 100px;
}

.paper-journal-ref:first-child {
    color: $grey-color;
    width: 100px;
}

.journal-title {
    font-style: italic;
}

.journal-volume {
//    font-weight: bold;
}

.journal-issue:before {
    content: "(";
}

.journal-issue:after {
    content: ")";
}

.journal-pages:before {
    content: ":";
}

.paper-conference-ref:first-child {
    color: $grey-color;
    width: 100px;
}

.conference-procname:before {
    content: "(";
}

.conference-procname:after {
    content: ")";
}

.paper-preprint-ref:first-child {
    color: $grey-color;
    width: 100px;
}

.preprint-arxiv-id:before {
    content: "arXiv:";
}

.paper-text:first-child {
    color: $grey-color;
    width: 100px;
}

.paper-abstract {
    display: block;
    margin-top: 1.5 * $spacing-unit;
}

.paper-abstract:before {
    content: "Abstract:";
    color: $grey-color;
//    font-weight: bold;
}

.paper-text-sep {
    font-size: $bullet-inline-scale;
}

/**
 * author list
 */
.author-list {
    margin-left: 0;
    padding-left: 0;
    display: inline;
}
.author-list li {
    display: inline;
}

.author-list li:not(:first-child):before {
    content: " • ";
    font-size: $bullet-inline-scale;
}

/**
 * paper lists
 */

.journal-papers-list, .conference-papers-list, .preprints-list {
    margin-bottom: 0.75 * $spacing-unit;
}

.paper-list-journal, .paper-list-conference, .paper-list-preprint {
    display: inline;
}
.paper-list-paper {
    display: block;
    margin-top: 0.5 * $spacing-unit;
}

.paper-list-title {
    display: block;
//    font-weight: bold;
    line-height: 1.25;
}

.paper-list-reference {
    display: block;
    color: $grey-color-dark;
    line-height: 1.25;
}

.paper-list-authors {
    display: block;
    color: $grey-color-dark;
    line-height: 1.25;
}

/**
 * contact information
 */
.contact-info-mailing-address {
    line-height: 1.25;
}

/**
 * Short bio
 */
.short-bio {
    display: block;
    clear: right;
}

/**
 * Picture and navigation stuff
 */
.home-page-picture {
    display: block;
    
    .picture {
        float: right;
        max-width: 25%;
        margin-bottom:  $spacing-unit;
    }
    
    .info {
        display: block;
//        padding-left: 35%;
        line-height: 1.25;
        font-size: 20px;
//        padding-top: 90px;
    }
    
    .name {
//        font-weight: bold;
        display: none;
    }
    
    .job, .workplace, .employer {
        color: $grey-color-dark;
        display: block;
    }
    
    @include media-query($on-palm) {
        display: none;
    }
}

/**
 * seminar talk announcements
 */

.seminar-blurb {
    border-top: solid 2px $grey-color-light;
    padding-top: 0.5 * $spacing-unit;
    border-bottom: solid 2px $grey-color-light;
    padding-bottom: 0.5 * $spacing-unit;
    
    p:last-child {
        margin-bottom: 0px;
    }
}

.seminar-talk-coords {
    margin-top: 1.5 * $spacing-unit;
    
    td.seminar-talk-place:first-child {
        color: $grey-color;
        width: 100px;
    }
    
    td.seminar-talk-time:first-child {
        color: $grey-color;
        width: 100px;
    }

    td.seminar-talk-video:first-child {
        color: $grey-color;
        width: 100px;
    }
}

.seminar-talk-data {
    margin-top: 1.5 * $spacing-unit;
    
    td.seminar-talk-speaker:first-child {
        color: $grey-color;
        width: 100px;
    }
    
    .seminar-talk-speaker-inst:before {
        content: "(";
    }
    
    .seminar-talk-speaker-inst:after {
        content: ")";
    }
    
    .seminar-talk-title:first-child{
        color: $grey-color;
        width: 100px;
    }
}

.seminar-talk-abstract {
    margin-top: 1.5 * $spacing-unit;
}

.seminar-talk-abstract:before {
//    font-weight: bold;
    content: "Abstract:";
    color: $grey-color;
}

/**
 * seminar home page
 */
.seminar-home-talks-list {
    border-top: solid 2px $grey-color-light;
    padding-top: 0.5 * $spacing-unit;
    margin-top: $spacing-unit;
    
    table {
        table-layout: fixed;
        width: 100%;
    }
    tr {
        line-height: 1.25;
    }
    
//    td:not(:first-child) {
//       padding-left: 20px;
//    }
    
    td {
        padding-bottom:  $base-line-height * $base-font-size;
    }
    
    .seminar-talk-date {
        width: 20%;
        
        @include media-query($on-palm) {
            width: 30%;
        }
    }
    
    .seminar-talk-title {
        width: 60%;
        @include media-query($on-palm) {
            display: none;
            width: 0%;
        }
    }
    
    .seminar-talk-speaker-inst:before {
        content: "(";
    }
    
    .seminar-talk-speaker-inst:after {
        content: ")";
    }
    
    .seminar-talk-speaker {
        width: 45%;
        @include media-query($on-palm) {
            width: 70%;
        }
    }
    
    .seminar-talk-title {
        padding-left: 20px;
    }
}

/**
 * CV
 */


.cv-body {
    
    line-height: 1.25;
    
    @mixin address-block {
        line-height: 1.25;
        padding-top: 60px;
        
    }
    
    @mixin list-block {
        margin-top: .66 * $base-line-height * $base-font-size;
    }
    
    @mixin tight-text {
        line-height: 1.25;
    }
    
    @mixin item-header {
        margin-bottom: .33 * $base-line-height * $base-font-size;
    }
    
    @mixin small-h2 {
        font-size: 20px;
        margin-bottom: 5px;
        margin-top: 15px;
    }
    
    .cv-main {
        clear: right;
    }
    
    .cv-header {
        margin-bottom: 2 * $spacing-unit;
    }
    
    .cv-header .address {
        float: left;
        clear: left;
        @include address-block;
    }
    
    .cv-header .contacts {
        padding-left: 220px;
        @include address-block;
    }
    
    .cv-header .picture {
        float: right;
        max-width: 15%;
    }
    
    .cv-footer {
        margin-top: 2 * $spacing-unit;
        color: $grey-color;
        font-size: 0.75 * $base-font-size;
        text-align: center;
    }
    
    h2 {
        font-size: 25px;
        margin-top: 20px;
        margin-bottom: 10px;
    }
    
    .research-interests {
        line-height: $base-line-height;
    }
    
    .education .deg:not(:first-child) {
        @include list-block;
    }
    
    .education .deg {
        .deg-date {
            color: $grey-color;
        }
        
        .deg-title {
//            font-weight: bold;
            font-style: italic;
        }
        
        .deg-note {
            padding-left: 25px;
            @include tight-text;
        }
        
        .deg-note:first-child {
            margin-top: .33 * $base-line-height * $base-font-size;
        }
    }
    
    .academic-positions {
        .position:not(:first-child) {
            @include list-block;
        }
        
        .pos-yearplace {
            @include item-header;
        }
        
        .pos-year {
            color: $grey-color;
        }
        
        .pos-title { 
//            font-weight: bold;
            font-style: italic;
        }
        
        .pos-dept, .pos-note {
            padding-left: 25px;
        }
        
        .pos-dept {
            @include item-header;
        }
        
        .pos-note {
            @include tight-text;
        }
    }

    .industry-positions {
        .position:not(:first-child) {
            @include list-block;
        }
        
        .pos-year {
            color: $grey-color;
        }
        
        .pos-title { 
//            font-weight: bold;
            font-style: italic;
        }
    }
    
    .funding-wrapper {
        .funding-item:not(:first-child) {
            @include list-block;
        }
        
        .funding-item {
            .instrument-year {
                @include item-header;
                
                .funding-year {
                    color: $grey-color;
                }
                
                .funding-title {
                    font-style: italic;
                }
            }
            
            .funding-item-notes {
                padding-left: 25px;
            }
        }
    }
    
    .publications {
        h2.page-heading {
            @include small-h2;
        }
    }
    
    .teaching {
        h2 {
            @include small-h2;
        }
        
        .courses-wrapper {
            .course:not(:first-child) {
                @include list-block;
            }
            
            .course {
                .course-term {
                    color: $grey-color;
                }
                
                .course-title {
                    font-style: italic;
                }
                
                .course-yearname {
                    margin-bottom: .33 * $base-line-height * $base-font-size;
                }
                
                .course-instcomment {
                    @include tight-text;
                    padding-left: 25px;
                }
            }
        }
        
        .seminars-wrapper {
            .seminar:not(:first-child) {
                @include list-block;
            }
            
            .seminar {
                .seminar-term {
                    color: $grey-color;
                }
                
                .seminar-title {
                    font-style: italic;
                }
                
                .seminar-yearname {
                    margin-bottom: .33 * $base-line-height * $base-font-size;
                }
                
                .seminar-instcomment {
                    @include tight-text;
                    padding-left: 25px;
                }
            }
        }
    }
    
    .service {
        h2 {
            @include small-h2;
        }
        
        .disscomm-wrapper {
            .disscomm:not(:first-child) {
                @include list-block;
            }
            
            .disscomm {
                .disscomm-yearname {
                    margin-bottom: .33 * $base-line-height * $base-font-size;
                }
                .disscomm-year {
                    color: $grey-color;
                }
            
                .disscomm-title {
                    @include tight-text;
                    padding-left: 25px;
                }
            
                .thesis-title {
                    font-style: italic;
                }
            }
        }
        
        .bachelortheses-wrapper {
            .bachelorthesis:not(:first-child) {
                @include list-block;
            }
            
            .bachelorthesis {
                .bt-yearname {
                    margin-bottom: .33 * $base-line-height * $base-font-size;
                }
                .bt-year {
                    color: $grey-color;
                }
            
                .bt-titletopic, .bt-myrole {
                    @include tight-text;
                    padding-left: 25px;
                }
            
                .bt-title {
                    font-style: italic;
                }
            }
        }
        
        .conforg-wrapper {
            .conference:not(:first-child) {
                @include list-block;
            }
            
            .conference {
                .conf-yearevent {
                    margin-bottom: .33 * $base-line-height * $base-font-size;
                }
                .conf-year {
                    color: $grey-color;
                }
                .conf-event {
                    font-style: italic;
                }

                .conf-myrole {
                    @include tight-text;
                    padding-left: 25px;
                }
            }
        }
        
        .deptservice-wrapper {
            .service:not(:first-child) {
                @include list-block;
            }
            
            .service {
                @include tight-text;
                
                .service-year {
                    color: $grey-color;
                }
            }
        }
        
        .refereeing-wrapper {
            
            h2 {
//                font-weight: bold;
                color: $grey-color;
                display: inline;
                font-size: $base-font-size;
            }
            
            h2:after {
                content: ": ";
            }
            
            .ref-journals {
                @include tight-text;
//                @include list-block;
                
                .ref-journal {
                    font-style: italic;
                }
                
                .ref-journal:not(:last-child):after {
                    content: ", ";
                }
            }
            
            .ref-conferences {
                @include tight-text;
                @include list-block;
                
                .ref-conference {
                    font-style: italic;
                }
                
                .ref-journal:not(:last-child):after {
                    content: ", ";
                }
            }
        }
    }
}

/**
 * home page
 */
.home {
    .home-research h1 {
        margin-top: $spacing-unit;
    }
}
